<template>
    <div class="page">
        <section class="docs">
            <header class="header-top">
                <img src="../assets/logo.svg" alt="">
                <nav class="nav-top">
                    <el-dropdown class="drop-menu btn">
                        <span class="el-dropdown-link fontColor">
                            中文<i class="el-icon-caret-bottom el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>中文</el-dropdown-item>
                            <el-dropdown-item>English</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <div class="BtnGroup">
                        <button class="fontColor BtnBlue btn">登录</button>
                        <button type="primary " class="btn BtnWrite">注册</button>
                    </div>
                </nav>
            </header>
        </section>
        <el-container class="docs2">
            <el-aside class="navGroup"  width="200px" >
                <navMenu></navMenu>
            </el-aside>
            <section class="docs2-right">
                <div>{{ $t('msg.msg1')  }}</div>
                <div >
                    【万科所有集团副总裁及以上级别目前都被边控了？万科：集团管理层境外公务出行正常进行】财联社4月14日电，蓝鲸新闻获悉，万科于4月14日下午召开线下投资者会议，就舆情提及出国不回的领导蔡平、王润川是什么情况?有传闻华中区首蔡平去美不归之后，万科所有集团副总裁及以上级别，目前都被边控了。万科方面称，“实际情况是，原华中区域首席合伙人蔡平，孩子在美国出生，现在到了接受教育的阶段，需要家庭陪伴。其于 2023 年提出辞职并获得公司同意。原总部协同中心牵头合伙人王润川，因前往香港深造提出离职，目前人常住深圳。集团管理层境外公务出行正常进行。集团总裁 祝九胜今天刚刚从香港考察项目回来。集团联席总 裁朱保全今日(4 月 14 日)中午飞往日本进行业务 考察。”（蓝鲸新闻 陈业）
                    【万科所有集团副总裁及以上级别目前都被边控了？万科：集团管理层境外公务出行正常进行】财联社4月14日电，蓝鲸新闻获悉，万科于4月14日下午召开线下投资者会议，就舆情提及出国不回的领导蔡平、王润川是什么情况?有传闻华中区首蔡平去美不归之后，万科所有集团副总裁及以上级别，目前都被边控了。万科方面称，“实际情况是，原华中区域首席合伙人蔡平，孩子在美国出生，现在到了接受教育的阶段，需要家庭陪伴。其于 2023 年提出辞职并获得公司同意。原总部协同中心牵头合伙人王润川，因前往香港深造提出离职，目前人常住深圳。集团管理层境外公务出行正常进行。集团总裁 祝九胜今天刚刚从香港考察项目回来。集团联席总 裁朱保全今日(4 月 14 日)中午飞往日本进行业务 考察。”（蓝鲸新闻 陈业）
                    【万科所有集团副总裁及以上级别目前都被边控了？万科：集团管理层境外公务出行正常进行】财联社4月14日电，蓝鲸新闻获悉，万科于4月14日下午召开线下投资者会议，就舆情提及出国不回的领导蔡平、王润川是什么情况?有传闻华中区首蔡平去美不归之后，万科所有集团副总裁及以上级别，目前都被边控了。万科方面称，“实际情况是，原华中区域首席合伙人蔡平，孩子在美国出生，现在到了接受教育的阶段，需要家庭陪伴。其于 2023 年提出辞职并获得公司同意。原总部协同中心牵头合伙人王润川，因前往香港深造提出离职，目前人常住深圳。集团管理层境外公务出行正常进行。集团总裁 祝九胜今天刚刚从香港考察项目回来。集团联席总 裁朱保全今日(4 月 14 日)中午飞往日本进行业务 考察。”（蓝鲸新闻 陈业）
                    【万科所有集团副总裁及以上级别目前都被边控了？万科：集团管理层境外公务出行正常进行】财联社4月14日电，蓝鲸新闻获悉，万科于4月14日下午召开线下投资者会议，就舆情提及出国不回的领导蔡平、王润川是什么情况?有传闻华中区首蔡平去美不归之后，万科所有集团副总裁及以上级别，目前都被边控了。万科方面称，“实际情况是，原华中区域首席合伙人蔡平，孩子在美国出生，现在到了接受教育的阶段，需要家庭陪伴。其于 2023 年提出辞职并获得公司同意。原总部协同中心牵头合伙人王润川，因前往香港深造提出离职，目前人常住深圳。集团管理层境外公务出行正常进行。集团总裁 祝九胜今天刚刚从香港考察项目回来。集团联席总 裁朱保全今日(4 月 14 日)中午飞往日本进行业务 考察。”（蓝鲸新闻 陈业）
                    【万科所有集团副总裁及以上级别目前都被边控了？万科：集团管理层境外公务出行正常进行】财联社4月14日电，蓝鲸新闻获悉，万科于4月14日下午召开线下投资者会议，就舆情提及出国不回的领导蔡平、王润川是什么情况?有传闻华中区首蔡平去美不归之后，万科所有集团副总裁及以上级别，目前都被边控了。万科方面称，“实际情况是，原华中区域首席合伙人蔡平，孩子在美国出生，现在到了接受教育的阶段，需要家庭陪伴。其于 2023 年提出辞职并获得公司同意。原总部协同中心牵头合伙人王润川，因前往香港深造提出离职，目前人常住深圳。集团管理层境外公务出行正常进行。集团总裁 祝九胜今天刚刚从香港考察项目回来。集团联席总 裁朱保全今日(4 月 14 日)中午飞往日本进行业务 考察。”（蓝鲸新闻 陈业）
                    【万科所有集团副总裁及以上级别目前都被边控了？万科：集团管理层境外公务出行正常进行】财联社4月14日电，蓝鲸新闻获悉，万科于4月14日下午召开线下投资者会议，就舆情提及出国不回的领导蔡平、王润川是什么情况?有传闻华中区首蔡平去美不归之后，万科所有集团副总裁及以上级别，目前都被边控了。万科方面称，“实际情况是，原华中区域首席合伙人蔡平，孩子在美国出生，现在到了接受教育的阶段，需要家庭陪伴。其于 2023 年提出辞职并获得公司同意。原总部协同中心牵头合伙人王润川，因前往香港深造提出离职，目前人常住深圳。集团管理层境外公务出行正常进行。集团总裁 祝九胜今天刚刚从香港考察项目回来。集团联席总 裁朱保全今日(4 月 14 日)中午飞往日本进行业务 考察。”（蓝鲸新闻 陈业）
                </div>
            </section>
        </el-container>
    </div>
</template>

<script>
import navMenu from '../components/navMenu.vue'
export default {
  components: { navMenu }
}
</script>

<style scoped>
.docs{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background: #fff;
}
.header-top{
    height: 64px;
    padding: 16px 0;
    box-sizing: border-box;
    margin: 0 25px;
}
.header-top img{
    width: 150px;
    height: 32px;
}
.nav-top{
    float: right;
    width: 50%;
    text-align: right;
    font-size: 0;
}
.BtnGroup{
    display: inline-block;
}
.fontColor{
    color: #2b7eee;
}
.BtnBlue{
    border: #2b7eee 1px solid;
    background-color: white;
    border-radius: 5px;
}
.BtnWrite{
    border: #2b7eee 1px solid;
    background-color: #2b7eee;
    border-radius: 5px;
    color: white;
}
.BtnBlue:hover{
    color: white;
    border: #2b7eee 1px solid;
    background-color: #2b7eee;
}
.BtnWrite:hover{
    color: #2b7eee;
    background-color: white;
}
.btn{
    height: 32px;
    width: 72px;
    margin-left: 16px;
    text-align: center;
    line-height: 30px;
    padding: 0;
}
.navGroup{
    position: fixed;
    top: 96px;
    z-index: 998;
    display: block;
    width: 200px;
    padding-left: 15px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
}
.docs2{
    position: relative;
    top: 94px;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
}
.docs2-right{
    width: 950px;
    margin-left: 250px;
    overflow-y: scroll;
}
</style>
