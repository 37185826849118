<template>
    <el-menu router :default-active="$route.path"  ctive-text-color="#2b7eee" background-color="#fff">
        <p class="api-tit">API 接入指南</p>
        <el-menu-item index="/docs/api/text/multiple" >
            多文本翻译
        </el-menu-item>
        <el-menu-item index="/docs/api/text/single" >
            单文本翻译
        </el-menu-item>
        <el-menu-item index="/docs/api/text/languageList">
            文本翻译语言列表
        </el-menu-item>
    </el-menu>
</template>

<script>
export default {

}
</script>

<style scoped>
ul::-webkit-scrollbar-button{
    display:none
}

ul::-webkit-scrollbar {
    width: 7px; /* 对垂直滚动条有效 */
}

/* 定义滚动条的轨道颜色、内阴影及圆角 */
ul::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
    border-radius: 5px;
}

/* 定义滑块颜色、内阴影及圆角 */
ul::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.066);
}
ul::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, .2);
}
.api-tit{
    padding-left: 20px;
    font-weight: 600;
    padding-bottom: 25px;
    font-size: 20px;
}
.el-menu-item{
    height: 35px;
    line-height: 15px;
    font-size: 16px;
    padding-left: 20px !important; 
}
.el-menu-item:hover {
    background-color: #fff !important;
}
.el-menu-item.is-active{
    background-color: #fff !important;
}
.el-menu{
    border-right: 0px;
}
</style>
